import { BackpackIcon, DashboardIcon, LayersIcon, ListBulletIcon, MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { useFetcher } from "@remix-run/react";
import { useEffect, useMemo, useState } from "react";
import { useRefinementList } from "react-instantsearch";
import { Button, Flex, Heading, ModalV2, MultiSelect, Select, TextInput } from "sparta";
import UIMessageAtom from "~/components/layout/UIMessageAtom";
import { SavedSearchIcon } from "~/icons";
import { SavedSearchActionResults } from "~/routes/api.saved-search/route";

interface SearchParams {
  name?: string;
  query: string;
  tags: string[];
  platforms: string[];
  postType: string[];
  agencies: string[];
  frequency?: string;
}

interface UpsertSavedSearchModalProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  defaultValues?: SearchParams;
  id?: string;
}

/**
 * Given a uiState from instantsearch, build a name for the search in the form
 * [DATE] - [TAGS].[PLATFORM].[AGENCY]
 * @param posts The uiState from instantsearch
 * @returns The name of the search
 */
function buildName(search: SearchParams) {
  const date = new Date().toLocaleDateString();
  const tags = search.tags?.join(".");
  const platforms = search.platforms?.join(".");
  const agency = search.agencies?.join(".");
  const postType = search.postType?.join(".");

  return `${date} - ${[tags, platforms, agency, postType, search.query].filter((v) => !!v).join(".")}`.toUpperCase();
}

export function UpsertSavedSearchModal({ open, onOpenChange, defaultValues, id }: UpsertSavedSearchModalProps) {
  const upsertFetcher = useFetcher<SavedSearchActionResults>();
  const [error, setError] = useState<string | null>(null);

  const { items: tsTags, searchForItems: searchTags } = useRefinementList({ attribute: "tags" });
  const { items: tsPlatforms } = useRefinementList({ attribute: "platforms" });
  const { items: tsCategories } = useRefinementList({ attribute: "postType" });
  const { items: tsAgencies } = useRefinementList({ attribute: "agency" });

  const [search, setSearch] = useState<SearchParams>({
    name: defaultValues?.name ?? "",
    query: defaultValues?.query ?? "",
    tags: defaultValues?.tags ?? [],
    platforms: defaultValues?.platforms ?? [],
    postType: defaultValues?.postType ?? [],
    agencies: defaultValues?.agencies ?? [],
    frequency: defaultValues?.frequency ?? "daily",
  });

  useEffect(() => {
    setSearch({
      name: defaultValues?.name ?? "",
      query: defaultValues?.query ?? "",
      tags: defaultValues?.tags ?? [],
      platforms: defaultValues?.platforms ?? [],
      postType: defaultValues?.postType ?? [],
      agencies: defaultValues?.agencies ?? [],
      frequency: defaultValues?.frequency,
    });
  }, [defaultValues]);

  useEffect(() => {
    if (upsertFetcher.state === "idle") {
      if (upsertFetcher.data?.status === 200) {
        onOpenChange(false);
      } else if (upsertFetcher.data?.message) {
        setError(upsertFetcher.data.message);
      }
    }
  }, [upsertFetcher.data, upsertFetcher.state]);

  const onSubmit = async () => {
    setError(null);
    upsertFetcher.submit(
      {
        action: id ? "update" : "create",
        search: {
          ...search,
          id,
          name: search.name || buildName(search),
        },
      } as any,
      {
        method: "POST",
        action: "/api/saved-search",
        encType: "application/json",
      },
    );
  };

  const handleOptionToggle = (attribute: string, value: string) => {
    // Check if the value is already selected
    const index = search[attribute].indexOf(value);
    if (index === -1) {
      setSearch({
        ...search,
        [attribute]: [...search[attribute], value],
      });
    } else {
      setSearch({
        ...search,
        [attribute]: search[attribute].filter((v) => v !== value),
      });
    }
  };

  const handleClear = (attribute: string) => {
    setSearch({
      ...search,
      [attribute]: [],
    });
  };

  return (
    <ModalV2.Root open={open} onOpenChange={onOpenChange}>
      <ModalV2.Content>
        <ModalV2.Title>Get Notified</ModalV2.Title>
        <ModalV2.Description color="gray">
          Stay in the loop with a Saved Search. Set your filters and get personalized email notifications whenever new
          content matches that your criteria is published. Enjoy tailored market intelligence.
        </ModalV2.Description>
        <Flex direction="column">
          <Flex align="center" justify="center" my="2">
            <SavedSearchIcon height="300" />
          </Flex>
          <TextInput
            formId="upsert-saved-search"
            label="Name your saved search (Optional)"
            hint="We'll set a default name for you if you leave this blank"
            value={search.name}
            onChange={(e) => setSearch({ ...search, name: e.target.value })}
          />
          <TextInput
            formId="upsert-saved-search"
            label="Keywords"
            defaultValue={search.query}
            leftSection={<MagnifyingGlassIcon />}
            onChange={(e) => setSearch({ ...search, query: e.target.value })}
          />
          <MultiSelect
            withTopLabel
            displayType="badge"
            label="Tags"
            placeholder="Tags"
            options={tsTags.map((t) => ({ label: t.value, value: t.value }))}
            selectedValues={search.tags?.map((t) => ({ label: t, value: t }))}
            onOptionToggle={(value) => handleOptionToggle("tags", value)}
            onSearchChange={(value) => searchTags(value)}
            shouldFilter={false}
            leftIcon={<ListBulletIcon />}
            onClearValue={(value) => handleOptionToggle("tags", value)}
            onClear={() => handleClear("tags")}
          />
          <MultiSelect
            withTopLabel
            displayType="badge"
            label="Platforms"
            placeholder="Platforms"
            options={tsPlatforms}
            selectedValues={search.platforms.map((p) => ({ label: p, value: p }))}
            onOptionToggle={(value) => handleOptionToggle("platforms", value)}
            leftIcon={<LayersIcon />}
            onClearValue={(value) => handleOptionToggle("platforms", value)}
            onClear={() => handleClear("platforms")}
          />
          <MultiSelect
            withTopLabel
            displayType="badge"
            label="Categories"
            placeholder="Categories"
            options={tsCategories.map((c) => ({ label: c.label, value: c.value }))}
            selectedValues={search.postType.map((p) => ({ label: p, value: p }))}
            onOptionToggle={(value) => handleOptionToggle("postType", value)}
            leftIcon={<DashboardIcon />}
            onClearValue={(value) => handleOptionToggle("postType", value)}
            onClear={() => handleClear("postType")}
          />
          <MultiSelect
            withTopLabel
            displayType="badge"
            label="Agencies"
            placeholder="Agencies"
            options={tsAgencies}
            selectedValues={search.agencies.map((a) => ({ label: a, value: a }))}
            onOptionToggle={(value) => handleOptionToggle("agencies", value)}
            leftIcon={<BackpackIcon />}
            onClearValue={(value) => handleOptionToggle("agencies", value)}
            onClear={() => handleClear("agencies")}
          />
          <Select
            formId="upsert-saved-search"
            style={{ width: "100%" }}
            label="Notification Frequency"
            name="frequency"
            options={["Instant", "Daily"]}
            // defaultValue={search.frequency === "instant" ? "Instant" : "Daily"}
            value={search.frequency === "instant" ? "Instant" : "Daily"}
            onValueChange={(value) => {
              setSearch({
                ...search,
                frequency: value === "Daily" ? "daily" : "instant",
              });
            }}
            hint="Receive notifications when new results are available. You can choose to receive notifications instantly or daily."
          />
          {error ? (
            <UIMessageAtom
              type="error"
              message={`An error ocurred while processing your request: ${error}. Please try again later.`}
            />
          ) : null}
          <Flex justify="end" mt="2">
            <ModalV2.Close>
              <Button color="gray" variant="soft">
                Cancel
              </Button>
            </ModalV2.Close>
            <Button
              disabled={upsertFetcher.state !== "idle"}
              loading={upsertFetcher.state !== "idle"}
              onClick={onSubmit}
            >
              {id ? "Update" : "Create"} Saved Search
            </Button>
          </Flex>
        </Flex>
      </ModalV2.Content>
    </ModalV2.Root>
  );
}
